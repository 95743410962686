<template>
  <div id="courseManage" class="container">
    <!-- <div class="ivy-title">
      <el-row :gutter="20">
        <el-col :span="20">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'Home' }">{{
              $t("courses.home")
            }}</el-breadcrumb-item>
            <el-breadcrumb-item>{{ $t("courses.courses") }}</el-breadcrumb-item>
            <el-breadcrumb-item>{{
              $t("courses.edit_course")
            }}</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
        <el-col :span="4" class="text-right">
          <el-button
            @click="$router.go(-1)"
            type="primary"
            icon="el-icon-arrow-left"
            >{{ $t("courses.back") }}</el-button
          >
        </el-col>
      </el-row>
    </div> -->
    <div class="ivy-title">
      <el-page-header
        :title="$t('courses.back')"
        @back="historyBack"
        :content="$t('courses.edit_course')"
      >
      </el-page-header>
    </div>

    <!-- 新增资源 -->
    <el-dialog
      :title="$t('courses.add_resources')"
      width="70%"
      :visible.sync="dialogVisible"
      v-if="course.playlist != undefined && course.playlist.length > 0"
    >
      <edit-play-child
        @dialog="dialogVisible = false"
        :langValue="langValue"
        :child="newPlayChildContent"
        :token="token"
        :play="
          course.playlist[witchItem.playlist_index].children[
            witchItem.playlistChild_index
          ]
        "
        :type="'addContent'"
      >
      </edit-play-child>
    </el-dialog>

    <!--保存和预览按钮-->
    <el-button
      @click="saveCourse"
      id="saveCourse"
      type="success"
      icon="el-icon-upload"
      >{{ $t("courses.save") }}
    </el-button>

    <el-row>
      <el-col :span="24">
        <div class="edit-area">
          <el-form label-width="100px" ref="form" :model="course">
            <div class="course-teacher">
              <el-form-item
                :label="$t('courses.teachers')"
                :rules="commonRules.input"
                :prop="'teacher_uid'"
              >
                <el-select
                  v-model="course.teacher_uid"
                  filterable
                  :placeholder="$t('courses.teachers')"
                  style="width:100%"
                >
                  <el-option
                    v-for="teacher in teachers_list"
                    :key="teacher.teacher_uid"
                    :label="teacher.username + '(' + teacher.email + ')'"
                    :value="'' + teacher.teacher_uid"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="course-title">
              <el-form-item
                :label="$t('courses.title')"
                :rules="commonRules.input"
                :prop="'course_name_' + langValue"
              >
                <el-input
                  v-model="course['course_name_' + langValue]"
                ></el-input>
              </el-form-item>
            </div>
            <div class="course-img">
              <el-form-item :label="$t('courses.cover')" required>
                <el-upload
                  class="avatar-uploader"
                  :action="postUrl"
                  :headers="token"
                  name="cover_photo"
                  :show-file-list="false"
                  :before-upload="beforeAvatarUpload"
                  :on-success="handleAvatarSuccess"
                  :on-remove="handleRemove"
                >
                  <img
                    referrer="no-referrer|origin|unsafe-url"
                    v-if="course.cover_photo"
                    :src="course.cover_photo"
                    class="ivyway-cover-photo"
                  />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
            </div>
            <div class="course-price">
              <el-form-item :label="$t('courses.price')" required>
                <el-input-number
                  v-model="course.price"
                  :precision="2"
                  :step="100"
                  :max="10000"
                  :min="0"
                ></el-input-number>
                <el-select v-model="course.price_currency" placeholder="">
                  <el-option :label="$t('courses.NTD')" value="NTD"></el-option>
                  <el-option :label="$t('courses.USD')" value="USD"></el-option>
                  <el-option :label="$t('courses.RMB')" value="RMB"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="course-description">
              <el-form-item
                :label="$t('courses.description')"
                :rules="commonRules.input"
                :prop="'description_' + langValue"
              >
                <el-input
                  type="textarea"
                  :rows="3"
                  v-model="course['description_' + langValue]"
                  required
                ></el-input>
              </el-form-item>
            </div>
          </el-form>
          <div class="course-body">
            <!--playlist-->
            <!-- options: 让editPlayChild不可拖拽 -->
            <draggable
              v-model="course.playlist"
              @change="resetOrder(course.playlist)"
              :options="{
                filter: '.editPlayChild,.editPlay',
                preventOnFilter: false
              }"
            >
              <div
                class="playlist"
                v-for="(play, index) in course.playlist"
                :key="index"
              >
                <div class="playlist-head">
                  <div v-show="play.isEditPlay == false">
                    {{ $t("courses.chapter") }} {{ index + 1 }}:
                    <span>{{ play["name_" + langValue] }}</span>
                    <span class="playlist-action">
                      <span @click="editPlay(play)"
                        ><i class="el-icon-edit"></i
                      ></span>
                      <span @click="deleteItem(index, course.playlist)"
                        ><i class="el-icon-delete"></i
                      ></span>
                    </span>
                    <span class="playlist-action" style="float: right">
                      <i class="el-icon-d-caret"></i>
                    </span>
                  </div>
                  <div class="editPlay" v-show="play.isEditPlay == true">
                    <el-form label-width="100px">
                      <el-form-item :label="$t('courses.chapter')">
                        <el-input
                          v-model="play['name_' + langValue]"
                          :placeholder="$t('courses.title')"
                          @keyup.enter.native="play.isEditPlay = false"
                        ></el-input>
                      </el-form-item>
                    </el-form>
                    <div class="editBtn">
                      <el-button
                        size="medium"
                        type="danger"
                        @click="resetPlay(play)"
                        icon="el-icon-circle-close"
                      ></el-button>
                      <el-button
                        size="medium"
                        type="success"
                        @click="play.isEditPlay = false"
                        icon="el-icon-circle-check"
                      ></el-button>
                    </div>
                  </div>
                </div>
                <!--children-->
                <draggable
                  :list="play.children"
                  group="playlistChild"
                  @change="resetOrder(play.children)"
                  :options="{
                    filter: '.editPlayChild',
                    preventOnFilter: false
                  }"
                >
                  <div
                    class=""
                    v-for="(child, childIndex) in play.children"
                    :key="childIndex"
                  >
                    <div
                      class="playlist-children"
                      v-show="child.isEditPlayChild == false"
                    >
                      <div
                        class="playlist-children-content playlist-children-content-first"
                      >
                        <b v-html="getName(child.type)"></b>
                        <span>{{ child["name_" + langValue] }}</span>
                        <span class="playlist-children-action">
                          <span @click="editPlayChild(child)">
                            <i class="el-icon-edit"></i>
                          </span>
                          <span @click="deleteItem(childIndex, play.children)">
                            <i class="el-icon-delete"></i>
                          </span>
                        </span>
                        <span
                          @click="child.showContents = !child.showContents"
                          class="showContents"
                        >
                          <i
                            v-show="!child.showContents"
                            class="el-icon-arrow-down"
                          ></i>
                          <i
                            v-show="child.showContents"
                            class="el-icon-arrow-up"
                          ></i>
                        </span>
                      </div>

                      <div v-show="child.showContents">
                        <div v-if="child.type == 'type_video'">
                          <div class="playlist-children-content">
                            <p>
                              <b>{{ $t("courses.type") }}: </b>
                              <span v-show="child.is_free">{{
                                $t("courses.free")
                              }}</span>
                              <span v-show="!child.is_free">{{
                                $t("courses.charge")
                              }}</span>
                            </p>
                            <p>
                              <b>{{ $t("courses.description") }}: </b
                              >{{ child["description_" + langValue] }}
                            </p>
                            <p>
                              <b>{{ $t("courses.video") }}: </b>
                              {{ child["url"] }}
                            </p>
                          </div>
                        </div>
                        <!-- <div v-if="child.type == 'type_practice'">
                          <div class="playlist-children-content"></div>
                        </div> -->
                        <div v-if="child.type == 'type_file'">
                          <div class="playlist-children-content">
                            <p>
                              <a class="el-upload-list__item-name"
                                ><i class="el-icon-document"></i>
                                {{ getFileName(child.url) }}
                              </a>
                            </p>
                          </div>
                        </div>
                        <div v-if="child.children != '0'">
                          <div
                            class="playlist-children-content"
                            v-for="(content, index) in child.children"
                            :key="index"
                          >
                            <p>
                              <b>{{ $t("courses.title") }}: </b
                              >{{ content["name_" + langValue] }}
                              <span class="playlist-action">
                                <span
                                  @click="deleteItem(index, child.children)"
                                >
                                  <i class="el-icon-delete"></i>
                                </span>
                              </span>
                            </p>
                            <p>
                              <a class="el-upload-list__item-name"
                                ><i class="el-icon-document"></i>
                                {{ getFileName(content.url) }}
                                <!-- {{ content.url }} -->
                              </a>
                            </p>
                          </div>
                        </div>
                        <div class="playlist-children-content">
                          <el-button
                            size="small"
                            round=""
                            @click="addchildContent(index, childIndex)"
                            ><b
                              ><i class="el-icon-plus"></i>
                              {{ $t("courses.resources") }}</b
                            >
                          </el-button>
                        </div>
                      </div>
                    </div>
                    <div class="editPlayChild">
                      <edit-play-child
                        :child="child"
                        :editPlayChildJson="editPlayChildJson"
                        :langValue="langValue"
                        :token="token"
                        :type="'edit'"
                        v-if="child.isEditPlayChild"
                      >
                      </edit-play-child>
                    </div>
                  </div>
                </draggable>
                <!--children-->

                <!--添加新的child-->
                <div class="addBtn" v-show="!play.isAddPlayChild">
                  <el-button
                    @click="addNewPlayChild(play)"
                    type="primary"
                    icon="el-icon-plus"
                  >
                  </el-button>
                </div>
                <div class="editPlayChild">
                  <edit-play-child
                    :child="newPlayChild"
                    :play="play"
                    :langValue="langValue"
                    :token="token"
                    :type="'add'"
                    v-if="play.isAddPlayChild"
                  >
                  </edit-play-child>
                </div>
              </div>
            </draggable>
            <!--playlist-->
            <div class="addBtn" v-show="!course.isAddPlay">
              <el-button
                @click="addNewPlay(course)"
                type="primary"
                icon="el-icon-plus"
              >
                {{ $t("courses.chapter") }}
              </el-button>
            </div>

            <div class="newPlay" v-show="course.isAddPlay">
              <el-form label-width="100px">
                <el-form-item :label="$t('courses.chapter')">
                  <el-input
                    v-model="newPlay['name_' + langValue]"
                    :placeholder="$t('courses.title')"
                  ></el-input>
                </el-form-item>
              </el-form>
              <div class="editBtn">
                <el-button
                  size="medium"
                  type="danger"
                  @click="course.isAddPlay = false"
                  icon="el-icon-circle-close"
                >
                </el-button>
                <el-button
                  size="medium"
                  type="success"
                  @click="addPlay(course)"
                  icon="el-icon-circle-check"
                >
                </el-button>
              </div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import onlineCourse from "@/apis/onlineCourse";
import JwtService from "@/common/jwt.service";

import formMixin from "@/mixins/form";

import draggable from "vuedraggable";
import EditPlayChild from "./children/EditPlayChild.vue";

let lang_text = {
  "ZH-CN": "简体中文",
  "ZH-TW": "繁體中文",
  "EN-US": "English"
};
let type_arr = {
  type_video: {
    "ZH-CN": "<i class='el-icon-film'></i> 视频",
    "ZH-TW": "<i class='el-icon-film'></i> 視頻",
    "EN-US": "<i class='el-icon-film'></i> Video"
  },
  type_practice: {
    "ZH-CN": "<i class='el-icon-pie-chart'></i> 练习",
    "ZH-TW": "<i class='el-icon-pie-chart'></i> 練習",
    "EN-US": "<i class='el-icon-pie-chart'></i> Practice"
  },
  type_file: {
    "ZH-CN": "<i class='el-icon-document'></i> 文档",
    "ZH-TW": "<i class='el-icon-document'></i> 文檔",
    "EN-US": "<i class='el-icon-document'></i> File"
  }
};
let new_arr = {
  newPlay: {
    order: 1,
    following_id: "",
    type: "type_playlist",
    children: [],
    isEditPlay: false,
    isAddPlayChild: false,
    "name_ZH-CN": "",
    "name_ZH-TW": "",
    "name_EN-US": ""
  },
  newPlayChild: {
    order: 1,
    is_free: 0,
    following_id: "",
    type: "type_video",
    videoType: "linkVideo",
    "name_ZH-CN": "",
    "name_ZH-TW": "",
    "name_EN-US": "",
    "description_ZH-CN": "",
    "description_ZH-TW": "",
    "description_EN-US": "",
    url: "",
    "transcript_ZH-CN": "",
    "transcript_ZH-TW": "",
    "transcript_EN-US": "",
    duration: 0,
    duration_his: "",
    children: [],
    practice_id: 0,
    isEditPlayChild: false,
    showContents: false
  },
  newPlayChildContent: {
    order: 1,
    following_id: "",
    "name_ZH-CN": "",
    "name_ZH-TW": "",
    "name_EN-US": "",
    type: "type_file",
    url: ""
  }
};

export default {
  name: "courseManage",

  components: {
    EditPlayChild,
    draggable
  },

  mixins: [formMixin],

  props: [],
  data() {
    return {
      token: { Authorization: `Bearer ${JwtService.getToken()}` },
      lang_text,
      type_arr,
      dialogVisible: false,
      isView: false,
      changeImg: false,
      old_courses: [],
      course: {},
      teachers_list: [],
      newCourse: {
        course_id: "",
        course_name: "",
        teacher_uid: "",
        "course_name_ZH-TW": "",
        "course_name_ZH-CN": "",
        "course_name_EN-US": "",
        cover_photo: "",
        description: "",
        "description_ZH-TW": "",
        "description_ZH-CN": "",
        "description_EN-US": "",
        language: "EN-US",
        playlist: [],
        price: 0,
        price_currency: "USD",
        isAddPlay: false
      },
      newPlay: {},
      newPlayChild: {},
      newPlayChildContent: {},
      editPlayJson: "",
      editPlayChildJson: "",
      witchItem: {
        playlist_index: 0,
        playlistChild_index: 0
      }
    };
  },
  computed: {
    ...mapGetters("user", ["langValue"]),
    courseId() {
      return this.$route.query.id;
    },
    postUrl() {
      return process.env.VUE_APP_BACKEND_URL + "upload_cover";
    }
  },
  watch: {},

  async mounted() {
    this.$i18n.locale = "en";
    this.changeLang({
      value: "EN-US",
      title: "English"
    });
    const data = await onlineCourse.courseEditor({
      course_id: this.courseId
    });
    this.setData(data);
  },

  methods: {
    ...mapActions("user", ["changeLang"]),
    // 初始化数据
    setData(data) {
      if (data[0]["status_code"] == 0) {
        this.course = JSON.parse(JSON.stringify(this.newCourse));
        this.course.course_id = this.courseId;

        this.teachers_list = data[0].all_teachers_data;
      } else {
        let courses = [];
        let course = {};
        for (let i in data) {
          if (data[i]["ZH-TW"]) {
            data[i]["ZH-TW"].language = "ZH-TW";
            courses.push(data[i]["ZH-TW"]);
          }
          if (data[i]["ZH-CN"]) {
            data[i]["ZH-CN"].language = "ZH-CN";
            courses.push(data[i]["ZH-CN"]);
          }
          if (data[i]["EN-US"]) {
            data[i]["EN-US"].language = "EN-US";
            courses.push(data[i]["EN-US"]);
          }
        }
        course = courses[0];
        for (let i in courses) {
          for (let lang in lang_text) {
            if (courses[i].language == lang) {
              course["course_name_" + lang] = courses[i]["course_name"];
              course["description_" + lang] = courses[i].description;
              if (courses[i].playlist != 0) {
                for (let play_index in courses[i].playlist) {
                  course.playlist[play_index]["name_" + lang] =
                    courses[i].playlist[play_index]["name"];

                  if (courses[i].playlist[play_index]["children"] != 0) {
                    for (let playchild_index in courses[i].playlist[play_index][
                      "children"
                    ]) {
                      courses[i].playlist[play_index]["children"][
                        playchild_index
                      ].active = false;
                      course.playlist[play_index]["children"][playchild_index][
                        "name_" + lang
                      ] =
                        courses[i].playlist[play_index]["children"][
                          playchild_index
                        ]["name"];
                      if (
                        course.playlist[play_index]["children"][playchild_index]
                          .type == "type_video"
                      ) {
                        // if(){
                        course.playlist[play_index]["children"][
                          playchild_index
                        ].videoType = "linkVideo";
                        // }
                        course.playlist[play_index]["children"][
                          playchild_index
                        ]["description_" + lang] =
                          courses[i].playlist[play_index]["children"][
                            playchild_index
                          ].description;

                        course.playlist[play_index]["children"][
                          playchild_index
                        ]["transcript_" + lang] =
                          courses[i].playlist[play_index]["children"][
                            playchild_index
                          ]["transcript"];
                      }

                      let contents =
                        courses[i].playlist[play_index]["children"][
                          playchild_index
                        ].children;

                      if (contents != "0") {
                        for (let i in contents) {
                          course.playlist[play_index]["children"][
                            playchild_index
                          ].children[i]["name_" + lang] = contents[i]["name"];
                        }
                      } else {
                        course.playlist[play_index]["children"][
                          playchild_index
                        ].children = [];
                      }
                    }
                  } else {
                    courses[i].playlist[play_index]["children"] = [];
                  }
                }
              } else {
                courses[i].playlist = [];
              }
            }
          }
        }
        course["isAddPlay"] = false;
        for (let i in course.playlist) {
          let playlist = course.playlist[i];
          playlist["isEditPlay"] = false;
          playlist["isAddPlayChild"] = false;
          for (let j in playlist.children) {
            playlist.children[j]["isEditPlayChild"] = false;
            playlist.children[j]["showContents"] = false;
          }
        }
        this.course = course;
        this.teachers_list = data[0].all_teachers_data;
      }
    },
    resetOrder(arr) {
      for (let index in arr) {
        arr[index].order = 1 + parseInt(index);
      }
    },
    addNewPlay(course) {
      course.isAddPlay = true;
      this.newPlay = JSON.parse(JSON.stringify(new_arr.newPlay));
    },
    addPlay(course) {
      let newPlay = JSON.parse(JSON.stringify(this.newPlay));
      course.playlist.push(newPlay);
      course.isAddPlay = false;
      this.resetOrder(course.playlist);
    },
    editPlay(play) {
      this.reset();
      play.isEditPlay = true;
      this.editPlayJson = JSON.parse(JSON.stringify(play));
    },
    resetPlay(play) {
      play.isEditPlay = false;
      play["name_" + this.langValue] = this.editPlayJson[
        "name_" + this.langValue
      ];
    },
    addNewPlayChild(play) {
      play.isAddPlayChild = true;
      this.newPlayChild = JSON.parse(JSON.stringify(new_arr.newPlayChild));
    },
    editPlayChild(child) {
      this.reset();
      child.isEditPlayChild = true;
      this.editPlayChildJson = JSON.parse(JSON.stringify(child));
    },
    addchildContent(index, childIndex) {
      this.dialogVisible = true;
      this.newPlayChildContent = JSON.parse(
        JSON.stringify(new_arr.newPlayChildContent)
      );
      this.witchItem.playlist_index = index;
      this.witchItem.playlistChild_index = childIndex;
    },
    reset() {
      let course = this.course;
      if (course.isAddPlay == true) {
        course.isAddPlay = false;
      }
      for (let key in course.playlist) {
        let play = course.playlist[key];
        if (play.isEditPlay == true) {
          play.isEditPlay = false;
          play["name_" + this.langValue] = this.editPlayJson[
            "name_" + this.langValue
          ];
        }
        if (play.children != "0") {
          for (let key1 in play.children) {
            if (play.children[key1].isEditPlayChild == true) {
              play.children[key1].isEditPlayChild = false;
              play.children[key1][
                "name_" + this.langValue
              ] = this.editPlayChildJson["name_" + this.langValue];
            }
          }
        }
      }
    },
    deleteItem(index, arr) {
      this.$confirm(
        this.$t("courses.delete_alert"),
        this.$t("courses.delete"),
        {
          confirmButtonText: this.$t("alert.confirm"),
          cancelButtonText: this.$t("alert.cancel"),
          type: "warning"
        }
      )
        .then(() => {
          arr.splice(index, 1);
          this.resetOrder(arr);
          this.$message({
            type: "success",
            message: this.$t("courses.save_success")
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("alert.cancel")
          });
        });
    },
    verifyCoourse() {
      let isValid = false;
      this.$refs.form.validate(valid => {
        isValid = valid;
      });
      return isValid;
    },
    saveCourse() {
      this.resetOrder(this.course.playlist);
      for (let index in this.course.playlist) {
        this.resetOrder(this.course.playlist[index].children);
      }
      if (this.verifyCoourse()) {
        let course_ZH_CN = JSON.parse(JSON.stringify(this.course));
        let course_ZH_TW = JSON.parse(JSON.stringify(this.course));
        let course_EN_US = JSON.parse(JSON.stringify(this.course));
        let courses = {
          "ZH-CN": course_ZH_CN,
          "ZH-TW": course_ZH_TW,
          "EN-US": course_EN_US
        };
        for (let lang in courses) {
          courses[lang].language = lang;
          courses[lang].course_name = courses[lang]["course_name_" + lang];
          courses[lang].description = courses[lang]["description_" + lang];
          let playlist = courses[lang].playlist;
          for (let index in playlist) {
            playlist[index].name = playlist[index]["name_" + lang];
            let children = playlist[index].children;
            for (let i in children) {
              children[i].name = children[i]["name_" + lang];
              children[i].description = children[i]["description_" + lang];
              let contents = children[i].children;
              if (contents.length > 0) {
                for (let j in contents) {
                  contents[j].name = contents[j]["name_" + lang];
                }
              }
            }
          }
        }
        this.saveCourseEditor(courses);
      }
    },
    async saveCourseEditor(courses) {
      await onlineCourse.saveCourseEditor(courses);
      this.$message({
        message: this.$t("courses.save_success"),
        type: "success"
      });
    },
    getName(type) {
      return type_arr[type][this.langValue] + " :";
    },
    //封面上传成功
    handleAvatarSuccess(res) {
      this.course.cover_photo = res.cover_photo_url;
    },
    //封面上传之前做检查
    beforeAvatarUpload() {},
    handleRemove() {},
    getFileName(url) {
      let index = url.lastIndexOf("/");
      let demandName = url.substring(index + 1, url.length);
      return demandName;
    }
  }
};
</script>

<style scoped>
b [class*=" el-icon-"],
[class^="el-icon-"] {
  font-weight: 700;
}

.ivy-title {
  margin: 20px 0 20px 0;
}

.ivy-title >>> .el-breadcrumb {
  line-height: 40px;
}

.addBtn {
  text-align: right;
  margin: 20px 10px 5px 10px;
}

.course-title h2 {
  margin: 0;
}

.course-img-img {
  display: block;
  width: 50%;
  margin-bottom: 10px;
}

.edit-area {
  padding: 45px 20px 20px 20px;
  background: #fff;
  box-shadow: 0 2px 8px 2px rgba(20, 23, 28, 0.15);
}

.newPlay {
  margin-top: 60px;
  border: 1px dashed #cacbcc;
  padding: 20px 10px;
  background-color: #f2f3f5;
  line-height: 40px;
}

.playlist {
  border: 1px solid #cacbcc;
  background-color: #f2f3f5;
  padding: 20px 10px;
  margin-top: 30px;
}

/*.playlist:hover {*/
/*box-shadow: 0 6px 6px #ccc;*/
/*}*/

.playlist-head {
  margin: 0 10px 20px 10px;
  font-size: 18px;
  color: #43a06b;
  font-weight: bold;
}

.playlist-head:hover {
  cursor: move;
}

.addPlayChild {
  margin: 0 10px 20px 10px;
  border: 1px dashed #cacbcc;
  background-color: #fff;
  padding: 10px;
}

.editBtn {
  margin-top: 10px;
  text-align: right;
}

.playlist-children {
  margin: 0 10px 20px 10px;
  background-color: #fff;
  border: 1px solid #cacbcc;
  /*padding: 0 10px;*/
  cursor: move;
  /*height: 46px;*/
  /*line-height: 44px;*/
}

.playlist-children-content {
  border-bottom: 1px solid #ccc;
  padding: 10px;
}

.playlist-children-content-first {
  border-bottom: none;
}

.playlist-children:hover {
  box-shadow: 0 2px 2px #ccc;
}

.playlist-children-action,
.playlist-action {
  display: none;
}

.playlist-children:hover .playlist-children-action,
.playlist-children-content:hover .playlist-action,
.playlist-head:hover .playlist-action {
  display: inline-block;
}

.playlist-children-action span:hover,
.playlist-action span:hover {
  cursor: pointer;
  color: #000;
}

.playlist-children .editPlay {
  border: none;
  padding: 0;
}

.showContents {
  cursor: pointer;
  float: right;
}

.course-img >>> .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.course-img >>> .avatar-uploader .el-upload:hover {
  border-color: #42a16a;
}
.course-img >>> .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 320px;
  height: 180px;
  line-height: 180px;
  text-align: center;
}
.course-img >>> .ivyway-cover-photo {
  width: 320px;
  height: 180px;
  display: block;
}

#saveCourse {
  position: fixed;
  top: 140px;
  right: 0;
  z-index: 999;
}

#viewCourse {
  position: fixed;
  top: 80px;
  right: 0;
  z-index: 999;
}
</style>
