<template>
  <div class="editPlay" draggable="false">
    <div class="radio">
      <div v-if="child.following_id == ''">
        <el-radio
          v-for="(type, index) in type_arr"
          v-model="child.type"
          :label="index"
          :key="index"
        >
          <span v-html="type[langValue]"></span>
        </el-radio>
      </div>
      <span v-else v-html="type_arr[child.type][langValue]"></span>
    </div>
    <el-divider></el-divider>

    <el-form
      :model="child"
      label-width="100px"
      ref="child"
      :validate-on-rule-change="false"
    >
      <el-form-item :label="$t('courses.free')" v-if="type != 'addContent'">
        <el-switch
          :active-value="1"
          :inactive-value="0"
          v-model="child.is_free"
          active-color="#13ce66"
          inactive-color="#ccc"
        >
        </el-switch>
      </el-form-item>

      <el-form-item
        :label="$t('courses.title')"
        :rules="commonRules.input"
        :prop="'name_' + langValue"
      >
        <el-input
          maxlength="80"
          show-word-limit
          v-model="child['name_' + langValue]"
          autofocus
        ></el-input>
      </el-form-item>
      <!--            type_video-->
      <div v-if="child.type == 'type_video'">
        <el-form-item
          :label="$t('courses.description')"
          :rules="commonRules.input"
          :prop="'description_' + langValue"
        >
          <el-input
            type="textarea"
            :rows="3"
            v-model="child['description_' + langValue]"
          ></el-input>
        </el-form-item>

        <el-form-item :label="$t('courses.video')" required>
          <el-select v-model="child.videoType">
            <el-option
              key="linkVideo"
              :label="$t('courses.third_party_video')"
              value="linkVideo"
            ></el-option>
            <!-- <el-option
              key="uploadVideo"
              label="本地上传"
              value="uploadVideo"
              disabled
            ></el-option> -->
            <!-- <el-option
              key="onlineVideo"
              label="Ivy-Way在线视频"
              value="onlineVideo"
              disabled
            ></el-option> -->
          </el-select>
          <!-- <div v-if="child.videoType == 'uploadVideo'">
            <el-upload class="upload" drag :limit="1" action="" multiple>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将视频拖到此处，或<em>点击上传</em>
              </div>
            </el-upload>
          </div> -->
        </el-form-item>
        <div v-if="child.videoType == 'linkVideo'">
          <el-form-item
            :label="$t('courses.video_link')"
            :rules="commonRules.input"
            prop="url"
          >
            <el-input v-model="child['url']"></el-input>
          </el-form-item>
        </div>
        <el-form-item
          :label="$t('courses.subtitle')"
          :rules="commonRules.input"
          :prop="'transcript_' + langValue"
        >
          <el-input type="textarea" v-model="child['transcript_' + langValue]">
          </el-input>
        </el-form-item>
      </div>
      <!--            type_video-->
      <!--            type_practices-->
      <div v-if="child.type == 'type_practice'">
        <el-form-item label="选择练习">
          <el-select v-model="child.practice_id" filterable style="width: 100%">
            <el-option
              v-for="practice in practices"
              :key="practice.id"
              :label="practice.title"
              :value="practice.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <!--            type_practices-->

      <!--            type_file-->
      <div v-if="child.type == 'type_file'">
        <el-form-item :label="$t('courses.file')" required prop="url">
          <el-upload
            class="upload-demo"
            :action="postUrl"
            :headers="token"
            :show-file-list="false"
            name="document"
            :before-upload="beforeAvatarUpload"
            :on-success="handleAvatarSuccess"
            drag
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              将文件拖到此处，或<em>点击上传</em>
            </div>
          </el-upload>
          <el-input v-model="child.url" disabled>
            <template slot="prepend">Http://</template>
          </el-input>
        </el-form-item>
      </div>
      <!--  type_file-->
    </el-form>

    <div class="editBtn" v-if="type == 'edit'">
      <el-button size="medium" type="danger" @click="resetPlayChild(child)">
        {{ $t("alert.cancel") }}
      </el-button>
      <el-button size="medium" type="success" @click="editPlayChild">
        {{ $t("alert.confirm") }}
      </el-button>
    </div>
    <div class="editBtn" v-if="type == 'add'">
      <el-button
        size="medium"
        type="danger"
        @click="play.isAddPlayChild = false"
      >
        {{ $t("alert.cancel") }}
      </el-button>
      <el-button size="medium" type="success" @click="addPlayChild(play)">
        {{ $t("alert.confirm") }}
      </el-button>
    </div>
    <div class="editBtn" v-if="type == 'addContent'">
      <el-button size="medium" type="success" @click="addChildContent(play)">
        {{ $t("alert.confirm") }}
      </el-button>
    </div>
  </div>
</template>

<script>
const practices = [
  {
    id: "1",
    title: "Verb Tense Practice",
    start_date: "2020-05-14",
    end_date: "2020-05-14",
    time: 100,
    questions: [1, 2, 3]
  },
  {
    id: "2",
    title: "Practices2",
    start_date: "2020-05-14",
    end_date: "2020-05-14",
    time: 100,
    questions: [2, 1, 3]
  },
  {
    id: "3",
    title: "Practices3",
    start_date: "2020-05-14",
    end_date: "2020-05-14",
    time: 100,
    questions: [3, 2, 1]
  }
];
import formMixin from "@/mixins/form";
export default {
  mixins: [formMixin],
  props: {
    play: {
      type: Object
    },
    child: {
      type: Object
    },
    token: {
      type: Object
    },
    langValue: {
      type: String
      // required: true
    },
    type: {
      type: String
    },
    editPlayChildJson: {
      type: Object
    },
    dialogVisible: {
      type: Boolean
    }
  },
  data() {
    return {
      practices
    };
  },
  computed: {
    type_arr: {
      get: function() {
        let arr = {};
        if (this.type == "addContent") {
          arr = {
            type_file: {
              "ZH-CN": "<i class='el-icon-document'></i> 文档",
              "ZH-TW": "<i class='el-icon-document'></i> 文檔",
              "EN-US": "<i class='el-icon-document'></i> File"
            }
          };
        } else {
          arr = {
            type_video: {
              "ZH-CN": "<i class='el-icon-film'></i> 视频",
              "ZH-TW": "<i class='el-icon-film'></i> 視頻",
              "EN-US": "<i class='el-icon-film'></i> Video"
            },
            // type_practice: {
            //   "ZH-CN": "<i class='el-icon-pie-chart'></i> 练习",
            //   "ZH-TW": "<i class='el-icon-pie-chart'></i> 練習",
            //   "EN-US": "<i class='el-icon-pie-chart'></i> Practice"
            // },
            type_file: {
              "ZH-CN": "<i class='el-icon-document'></i> 文档",
              "ZH-TW": "<i class='el-icon-document'></i> 文檔",
              "EN-US": "<i class='el-icon-document'></i> File"
            }
          };
        }
        return arr;
      },
      set: function() {}
    },
    postUrl() {
      return process.env.VUE_APP_BACKEND_URL + "upload_document";
    }
  },
  watch: {},
  mounted() {},
  methods: {
    fileSuccess(res, file) {
      console.log(res);
      console.log(file);
    },
    verifyChild() {
      let isValid = false;
      this.$refs.child.validate(valid => {
        isValid = valid;
      });
      return isValid;
    },
    editPlayChild() {
      if (this.verifyChild()) {
        this.child.isEditPlayChild = false;
      }
    },
    addPlayChild(play) {
      if (this.verifyChild()) {
        let newPlayChild = JSON.parse(JSON.stringify(this.child));
        play.children.push(newPlayChild);
        play.isAddPlayChild = false;
        this.resetOrder(play.children);
      }
    },
    addChildContent(play) {
      if (this.verifyChild()) {
        let newPlayChild = JSON.parse(JSON.stringify(this.child));
        play.children.push(newPlayChild);
        this.resetOrder(play.children);
        this.$emit("dialog");
      }
    },
    resetOrder(arr) {
      for (let index in arr) {
        arr[index].order = 1 + parseInt(index);
      }
    },
    resetPlayChild(child) {
      child.isEditPlayChild = false;
      child["name_" + this.langValue] = this.editPlayChildJson[
        "name_" + this.langValue
      ];
      child["description_" + this.langValue] = this.editPlayChildJson[
        "description_" + this.langValue
      ];
    },
    //文件上传成功
    handleAvatarSuccess(res, file) {
      console.log(file);
      console.log(res);
      this.child.url = res.document_url;
    },
    //封面上传之前做检查
    beforeAvatarUpload(file) {
      console.log(file);
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$message.error("文件大小不能超过 20MB!");
      }
      return isLt20M;
    }
  }
};
</script>
<style>
.input-error > .el-input__inner,
.input-error > .el-textarea__inner {
  border: 1px solid #f56c6c;
}
</style>
<style scoped>
.radio {
  height: 40px;
  line-height: 40px;
  font-size: 16px;
}

.editPlay {
  background-color: #fff;
  border: 1px solid #cacbcc;
  margin: 10px;
  padding: 10px;
}

.text_area {
  margin-top: 10px;
}

.upload {
  margin-top: 10px;
  /*text-align: center;*/
}
.el-divider--horizontal {
  margin: 10px 0;
}
.editBtn {
  text-align: right;
}
</style>
